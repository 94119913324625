import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import Login from './Login';
import Poll from './Poll';
import RespondentDraw from './RespondentDraw';
import Call from './Call';
import { getToken } from './token';
import { Redirect } from 'react-router';

function App() {
  return (
    <Router>
      <Route path="/" render={props => {
        let token = getToken();
        if(window.location.pathname.startsWith("/app")) {
          if(!token) {
            return <Redirect to="/login"/>;
          } else if(window.location.pathname === "/app") {
            return <Redirect to="/app/sorteio-respondente"/>;
          }
        } else if(token) {
          return <Redirect to="/app/sorteio-respondente"/>;
        }
      }}/>
      <Route path="/login" component={Login}/>
      <Route path="/app/sorteio-respondente" component={RespondentDraw} />
      <Route path="/app/ligacao/:id" render={props => {
        const callId = props.match.params.id;
        return (
          <Call id={callId}/>
        );
      }
      }/>
      <Route path="/app/pesquisa/:id" render={props => {
        const pollId = props.match.params.id;
        return (
          <Poll id={pollId}/>
        );
      }}>
      </Route>
    </Router>
  );
}

export default App;
