import React from 'react';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import FormControl from '@material-ui/core/FormControl';

import TextField from '@material-ui/core/TextField';

import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';

import Switch from '@material-ui/core/Switch';

import Typography from '@material-ui/core/Typography';

import RadioGroup from '@material-ui/core/RadioGroup';

import axios from 'axios';

import { getToken } from './token';

import { Redirect } from 'react-router';
import { CircularProgress, Box, Paper, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import AlertDialog, { ConfirmationDialog } from './AlertDialog';

const styles = {
  card: {
    padding: 0,
    flex: 1,
    height: 'calc(100% - 40px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    flexBasis: 56,
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 10,
    paddingLeft: 10
  },
  content: {
    width: '100%',
    flex: 1,
    display: 'flex',
    padding: 20,
    overflowY: 'auto',
  },
  footer: {
    width: '100%',
    flexBasis: 64,
    paddingRight: 20,
    paddingLeft: 20
  },
  spinner: {
    display: 'flex',
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(144,56,66)',
    },
  },
});

function Header({currentQuestionIdx, setCurrentQuestionIdx, nQuestions}) {
  return (
    <div style={{width: '100%', display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <IconButton
        style={{
          backgroundColor: currentQuestionIdx === 0 ? '#bdc3c7' : 'rgb(144,56,66)',
          color: currentQuestionIdx === 0 ? '#7f8c8d' : '#fff'
        }}
        size="small"
        aria-label="left"
        onClick={() => {
          if(currentQuestionIdx > 0) {
            setCurrentQuestionIdx(currentQuestionIdx-1);
          }
        }}
        disabled={currentQuestionIdx === 0}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Typography component="h6" variant="h6" style={{color: "rgb(144,56,66)"}}>
        <span>Questão {currentQuestionIdx + 1}/{nQuestions}</span>
      </Typography>
      <IconButton
        style={{
          backgroundColor: currentQuestionIdx === nQuestions - 1 ? '#bdc3c7' : 'rgb(144,56,66)',
          color: currentQuestionIdx === nQuestions - 1 ? '#7f8c8d' : '#fff'
        }}
        size="small"
        aria-label="right"
        onClick={() => {
          if(currentQuestionIdx < nQuestions-1) {
            setCurrentQuestionIdx(currentQuestionIdx+1);
          }
        }}>
        <ChevronRightIcon />
      </IconButton>
    </div>
  );
}

async function fetchQuestions(takenPollId, setQuestions, setAnswers, setRefused, handleError, setRedirect) {
  let token = getToken();
  try {
    let res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/taken-poll/${takenPollId}/questions`, {headers: {"x-auth": token}});
    if (res.status === 200) {
      setQuestions(res.data.questions);
      if(res.data.taken_poll.finished_at) {
        window.localStorage.removeItem('ongoing_id');
        window.localStorage.removeItem('ongoing_answers');
        window.localStorage.removeItem('ongoing_refused');
        return setRedirect(true);
      }
      let oldAnswers = window.localStorage.getItem('ongoing_answers') || '';
      try {
        setAnswers(JSON.parse(oldAnswers))
      } catch(e) {
        setAnswers(new Array(res.data.questions.length));
      }
      let oldRefused = window.localStorage.getItem('ongoing_refused') || '';
      try {
        setRefused(JSON.parse(oldRefused))
      } catch(e) {
        let refused = res.data.questions.map(q => false);
        setRefused(refused);
      }
    } else {
      handleError();
    }
  } catch (err) {
    handleError();
  }
 }

function Spinner() {
  return (
    <div style={styles.spinner}>
      <CircularProgress />
    </div>
  );
}

function Poll(props) {
  let id = props.id;
  const [redirect, setRedirect] = React.useState(false);
  const [answers, setAnswers] = React.useState([]);
  const [refused, setRefused] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [currentQuestionIdx, setCurrentQuestionIdx] = React.useState(0);
  const [isSending, setIsSending] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [errorAlert, setErrorAlert] = React.useState('');
  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const [confirmationMessage, setConfirmationMessage] = React.useState('');

  const handleOk = () => {
    setAlertOpen(false);

    setRedirect(true);
  };

  const handleOkError = () => {
    setErrorAlert('');
    setErrorAlertOpen(false);
  };

  const handleError = msg => {
    if(msg) {
      setErrorAlert(msg);
    }
    setErrorAlertOpen(true);
  };

  React.useEffect(() => {
    window.localStorage.setItem('ongoing_id', id);
    fetchQuestions(id, setQuestions, setAnswers, setRefused, handleError, setRedirect);
  }, [id]);
  if(redirect) {
    return <Redirect to="/app/sorteio-respondente"/>;
  }
  if(questions.length === 0) {
    return (
      <div>
      </div>
    );
  }
  const confirmPollEnd = () => {
    const pendingQuestions = [];
    const pendingQuestionPositions = [];
    questions.forEach((q, idx) => {
      if(!answers[idx] && !refused[idx]) {
        pendingQuestions.push(q.id);
        pendingQuestionPositions.push(q.position);
      }
    });
    let msg = "Deseja mesmo encerrar a entrevista?";
    if(pendingQuestions.length > 0) {
      if(pendingQuestions.length === 1) {
        msg = `A questão ${pendingQuestionPositions[0]} está sem resposta. ` + msg;
      } else {
        msg = `As questões ${pendingQuestionPositions.join(',').replace(/,([^,]*)$/, " e $1")} estão sem resposta. ` + msg;
      }
    }
    setConfirmationMessage(msg);
  };
  const finishPoll = () => {
    const pendingQuestions = [];
    questions.forEach((q, idx) => {
      if(!answers[idx] && !refused[idx]) {
        pendingQuestions.push(q.id);
      }
    });
    setConfirmationMessage('');
    setIsSending(true);
    const token = getToken();
    const payload = questions.map((q, idx) => {
      let ans = answers[idx];
      if((ans === null) || (ans === undefined)) {
        return {
          poll_question_id: q.id,
          refused: true,
        };
      }
      if(q.type === "multiple_choice") {
        ans = parseInt(ans, 10);
      } else if (q.type === "checkbox") {
         ans = ans.map(a => parseInt(a, 10));
      }
      return {
        poll_question_id: q.id,
        answer: ans
      };
    });
    axios.post(
      `${process.env.REACT_APP_API_HOST}/api/taken-poll/${id}/answers`,
      {answers: payload},
      {headers: {"x-auth": token}}
    )
      .then(res => {
        setIsSending(false);
        if (res.status === 200) {
          window.localStorage.removeItem('ongoing_id');
          window.localStorage.removeItem('ongoing_answers');
          window.localStorage.removeItem('ongoing_refused');
          setAlertOpen(true);
        } else {
          setErrorAlertOpen(true);
        }
      })
      .catch(err => {
        setErrorAlertOpen(true);
        setIsSending(false);
      });
  };
  let setAnswer = val => {
    let newAnswers = [
      ...answers.slice(0, currentQuestionIdx),
      val,
      ...answers.slice(currentQuestionIdx + 1)
    ];
    window.localStorage.setItem('ongoing_answers', JSON.stringify(newAnswers));
    setAnswers(newAnswers);
  };
  let setRef = val => {
    let newRefused = [
      ...refused.slice(0, currentQuestionIdx),
      val,
      ...refused.slice(currentQuestionIdx + 1)
    ];
    window.localStorage.setItem('ongoing_refused', JSON.stringify(newRefused));
    setRefused(newRefused);
  };
  let qProps = {
    ...questions[currentQuestionIdx],
    answer: answers[currentQuestionIdx],
    refused: refused[currentQuestionIdx],
    setAnswer,
    setRefused: setRef
  };
  return (
    <Box display="flex" height="100%">
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ConfirmationDialog
          title={'Concluir Pesquisa?'}
          message={confirmationMessage}
          open={confirmationMessage !== ''}
          onClickOk={() => finishPoll()}
          onClickCancel={() => setConfirmationMessage('')}
        />
        <AlertDialog
          title={'Sucesso'}
          message={'Entrevista enviada com sucesso'}
          open={alertOpen}
          onClickOk={handleOk}
        />
        <AlertDialog
          title={'Erro'}
          message={errorAlert || 'Verifique sua conexão e se o problema persistir contate o suporte'}
          open={errorAlertOpen}
          onClickOk={handleOkError}
        />
        <Container style={{display: 'flex', alignItems: 'center'}} component="main" maxWidth="xs">
          <Paper style={styles.card}>
            <div style={styles.header}>
              <Header currentQuestionIdx={currentQuestionIdx} setCurrentQuestionIdx={setCurrentQuestionIdx} nQuestions={questions.length}/>
            </div>
            <div style={styles.content}>
              {isSending
                ? <Spinner />
                : <Question {...qProps}/>
              }
            </div>
            <div style={styles.footer}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={confirmPollEnd}
                disabled={isSending}
              >
                Finalizar pesquisa
              </Button>
            </div>
          </Paper>
        </Container>
      </MuiThemeProvider>
    </Box>
  );
}

function Question({id, type, body, alternatives, answer, setAnswer, refused, setRefused}) {
  if(type === "dissertative") {
    return (
      <DissertativeQuestion key={id} body={body} answer={answer} setAnswer={setAnswer} refused={refused} setRefused={setRefused}/>
    );
  } else if (type === "multiple_choice") {
    return (
      <MultipleChoiceQuestion key={id} body={body} alternatives={alternatives} answer={answer} setAnswer={setAnswer} refused={refused} setRefused={setRefused}/>
    );
  } else if (type === "checkbox"){
    return (
      <CheckboxQuestion key={id} body={body} alternatives={alternatives} answer={answer} setAnswer={setAnswer} refused={refused} setRefused={setRefused}/>
    );
  }
  return (
    <div>
      Questao
    </div>
  );
}

function DissertativeQuestion({body, answer, setAnswer, refused, setRefused}) {
  const handleAnswerChange = event => {
    setAnswer(event.target.value);
  };
  const handleRefusedChange = event => {
    if(!refused) {
      setAnswer('');
    }
    setRefused(!refused);
  };
  return (
    <div>
      <Typography component="h5" variant="h6">
        {body}
      </Typography>
      <TextField disabled={refused} fullWidth label="" value={answer || ""} onChange={handleAnswerChange} style={{marginBottom: "20px"}}/>
      <div>
        <span>N.R.</span>
        <Switch
          checked={!!refused}
          onChange={handleRefusedChange}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>
    </div>
  );
}

function MultipleChoiceQuestion({body, alternatives, answer, setAnswer, refused, setRefused}) {
  const handleAnswerChange = event => {
    setAnswer(event.target.value);
  };
  const handleRefusedChange = event => {
    if(!refused) {
      setAnswer(null);
    }
    setRefused(!refused);
  };
  answer = answer || null;
  return (
    <div>
      <Typography component="h5" variant="h6">
        {body}
      </Typography>
      <FormControl component="fieldset" disabled={refused}>
        <RadioGroup value={answer} onChange={handleAnswerChange}>
          {alternatives.map(a => {
            return (
              <FormControlLabel key={a.id} value={a.id.toString()} control={<Radio color="primary" />} label={a.body} style={{marginBottom: "5px"}}/>
            );
          })}
        </RadioGroup>
      </FormControl>
      <div>
        <span>N.R.</span>
        <Switch
          checked={!!refused}
          onChange={handleRefusedChange}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>
    </div>
  );
}

function CheckboxQuestion({body, alternatives, answer, setAnswer, refused, setRefused}) {
  function handleChange(id) {
    answer = answer || [];
    let found = answer.find(x => x === id);
    if(found) {
      answer = answer.filter(x => x !== id);
    } else {
      answer = answer.concat([id]);
    }
    setAnswer(answer);
  }
  const handleRefusedChange = event => {
    if(!refused) {
      setAnswer(null);
    }
    setRefused(!refused);
  };
  return (
    <div>
      <Typography component="h5" variant="h6">
        {body}
      </Typography>
      <FormControl component="fieldset" disabled={refused}>
        <FormGroup aria-label="position">

          {alternatives.map(a => {
            return (
              <FormControlLabel
                key={a.id}
                value={true}
                control={<Checkbox color="primary" checked={!!(answer || []).find(x => x === a.id)}/>}
                label={a.body}
                onChange={() => handleChange(a.id)}
                style={{marginBottom: "5px"}}
              />
            );
          })}
        </FormGroup>
      </FormControl>
      <div>
        <span>N.R.</span>
        <Switch
          checked={!!refused}
          onChange={handleRefusedChange}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>
    </div>
  );
}

export default Poll;
