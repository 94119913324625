import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import { setToken } from './token';

import { Redirect } from 'react-router';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://fpabramo.org.br/">
        Fundação Perseu Abramo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(144,56,66)',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function handleSubmit(event, email, password, setRedirect) {
  event.preventDefault();
  axios.post(`${process.env.REACT_APP_API_HOST}/auth`, {
    email: email.trim().toLowerCase(),
    password
  })
    .then(res => {
      setToken(res.data.token);
      setRedirect(true);
      console.log(res);
    })
    .catch(err => {
      console.log(err);
      if(err.response && (err.response.status === 401)) {
        alert('Email/senha inválido(s)');
      } else {
        alert('Erro desconhecido. Contate o suporte');
      }
    });
}

function Login() {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [redirect, setRedirect] = React.useState(false);
  
  if(redirect) {
    return (
      <Redirect to="/app/sorteio-respondente"/>
    );
  }
  return (
    <Container component="main" maxWidth="xs">
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h5" variant="h2" style={{color: ""}}>
            FPA
          </Typography>
          <Typography component="h1" variant="h5">
            Portal do Pesquisador
          </Typography>
          <form className={classes.form} noValidate onSubmit={e => handleSubmit(e, email, password, setRedirect)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              value={password}
              onChange={event => setPassword(event.target.value)}
              autoComplete="current-password"
            />
            <Button
              style={{backgroundColor: 'rgb(144,56,66)'}}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Entrarx
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Esqueceu a senha?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </MuiThemeProvider>
    </Container>
  );
}

export default Login;
