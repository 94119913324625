import React from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getToken } from './token';

import { Redirect } from 'react-router';
import AlertDialog, { ConfirmationDialog } from './AlertDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

async function getCall(callId, setCall, handleError) {
  let token = getToken();
  try {
    let res = await axios.get(`${process.env.REACT_APP_API_HOST}/api/poll-call/${callId}`, {headers: {"x-auth": token}});
    if (res.status === 200) {
      setCall({
        id: res.data.id,
        phone: res.data.poll_respondent.number
      });
    } else {
      handleError();
    }
  } catch (err) {
    handleError();
  }
}

async function handleDenial(callId, setDenial, handleError) {
  let token = getToken();
  try {
    let resp = await axios.post(`${process.env.REACT_APP_API_HOST}/api/poll-denial`, {
      poll_call_id: callId,
    }, {headers: {"x-auth": token}});
    if (resp.status === 200) {
      setDenial(true);
    } else {
      handleError();
    }
  } catch (err) {
    handleError();
  }
}

async function handleAcceptance(callId, setAcceptance, handleError) {
  let token = getToken();
  try {
    let resp = await axios.post(`${process.env.REACT_APP_API_HOST}/api/taken-poll`, {
      poll_call_id: callId,
    }, {headers: {"x-auth": token}});
    if (resp.status === 200) {
      setAcceptance(resp.data.id);
    } else {
      handleError();
    }
  } catch (err) {
    handleError();
  }
}

async function handleNoResponse(callId, setNoResponse, handleError) {
  let token = getToken();
  try {
    let resp = await axios.put(`${process.env.REACT_APP_API_HOST}/api/poll-call/${callId}/status`, {
      status: "finished",
    }, {headers: {"x-auth": token}});
    if (resp.status === 200) {
      setNoResponse(true);
    } else {
      handleError();
    }
  } catch (err) {
    handleError();
  }
}

function Call({id}) {
  const [call, setCall] = React.useState({});
  const [takenPollId, setTakenPollId] = React.useState(0);
  const [pollDenialId, setPollDenialId] = React.useState(0);
  const [noResponse, setNoResponse] = React.useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const [acceptedOpen, setAcceptedOpen] = React.useState(false);
  const [didntAcceptOpen, setDidntAcceptOpen] = React.useState(false);
  const [noPickUpOpen, setNoPickUpOpen] = React.useState(false);
  const classes = useStyles();
  React.useEffect(() => {
    getCall(id, setCall, handleError);
  }, [id]);

  const handleOkError = () => {
    setErrorAlertOpen(false);
  };

  const handleError = () => {
    setErrorAlertOpen(true);
  };

  if(takenPollId) {
    return (
      <Redirect to={`/app/pesquisa/${takenPollId}`}/>
    );
  }
  if(pollDenialId) {
    return (
      <Redirect to="/app/sorteio-respondente"/>
    );
  }
  if(noResponse) {
    return (
      <Redirect to="/app/sorteio-respondente"/>
    );
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <AlertDialog
        title={'Erro'}
        message={'Verifique sua conexão e se o problema persistir contate o suporte'}
        open={errorAlertOpen}
        onClickOk={handleOkError}
      />
      <ConfirmationDialog
        title={'Confirmação de Início'}
        message={'Iniciar pesquisa com respondente?'}
        open={acceptedOpen}
        onClickOk={() => handleAcceptance(id, setTakenPollId, handleError)}
        onClickCancel={() => setAcceptedOpen(false)}
      />
      <ConfirmationDialog
        title={'Confirmação de Recusa'}
        message={'O respondente recusou participar da pesquisa?'}
        open={didntAcceptOpen}
        onClickOk={() => handleDenial(id, setPollDenialId, handleError)}
        onClickCancel={() => setDidntAcceptOpen(false)}
      />
      <ConfirmationDialog
        title={'Confirmação de Indisponibilidade'}
        message={'O respondente não atendeu o telefone?'}
        open={noPickUpOpen}
        onClickOk={() => handleNoResponse(id, setNoResponse, handleError)}
        onClickCancel={() => setNoPickUpOpen(false)}        
      />
      <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
        { call && call.phone && <Link href={`tel: ${call ? call.phone : ''}`}>Ligar</Link> }
      </div>
      <Button
        style={{backgroundColor: 'rgb(144,56,66)'}}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={() => setNoPickUpOpen(true)}
      >
        Não Atendeu
      </Button>
      <Button
        style={{backgroundColor: 'rgb(144,56,66)'}}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={() => setAcceptedOpen(true)}
      >
        Aceitou
      </Button>
      <Button
        style={{backgroundColor: 'rgb(144,56,66)'}}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={() => setDidntAcceptOpen(true)}
      >
        Recusou
      </Button>
    </Container>
  );
}

export default Call;
