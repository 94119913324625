import React from 'react';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { getToken, deleteToken } from './token';
import axios from 'axios';

import { Redirect } from 'react-router';
import AlertDialog from './AlertDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

async function drawRespondent(setCallId, handleError) {
  let token = getToken();
  try {
    let resp = await axios.post(`${process.env.REACT_APP_API_HOST}/api/poll-call`, {}, {headers: {"x-auth": token}});
    if (resp.status === 200) {
      setCallId(resp.data.id);
    } else {
      handleError();
    }
  } catch(e) {
    console.log(e);
    handleError();
  }
}

async function logout(setLogoutRedirect) {
  deleteToken();
  setLogoutRedirect(true);
}


function RespondentDraw() {
  const [callId, setCallId] = React.useState("");
  const [pollId, setPollId] = React.useState("");
  const [goToOngoingPoll, setGoToOngoingPoll] = React.useState(false);
  const [logoutRedirect, setLogoutRedirect] = React.useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const classes = useStyles();
  React.useEffect(() => {
    let ongoing = window.localStorage.getItem('ongoing_id');
    if(ongoing) {
      try {
        setPollId(parseInt(ongoing, 10));
      } catch(e) {
      }
    }
  }, []);
  if(callId) {
    return (
      <Redirect to={`/app/ligacao/${callId}`}/>
    );
  }


  if(goToOngoingPoll) {
    return (
        <Redirect to={`/app/pesquisa/${pollId}`}/>
    );
  }

  if(logoutRedirect) {
    return (
      <Redirect to={`/login`}/>
    );
  }

  const handleOkError = () => {
    setErrorAlertOpen(false);
  };

  const handleError = () => {
    setErrorAlertOpen(true);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <AlertDialog
        title={'Erro'}
        message={'Verifique sua conexão e se o problema persistir contate o suporte'}
        open={errorAlertOpen}
        onClickOk={handleOkError}
      />
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        Olá, Pesquisador(a)
      </Typography>
      <Button
        style={{backgroundColor: 'rgb(144,56,66)'}}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => drawRespondent(setCallId, handleError)}
        className={classes.submit}
      >
        Nova entrevista
      </Button>
      <Button
        style={{backgroundColor: 'rgb(144,56,66)'}}
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => logout(setLogoutRedirect)}
        className={classes.submit}
      >
        Sair
      </Button>
      {pollId &&       <Button
        style={{backgroundColor: 'rgb(144,56,66)'}}
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => setGoToOngoingPoll(true)}
        className={classes.submit}
      >
        Ir à pesquisa pendente
      </Button>
      }
    </div>
    </Container>
  );
}

export default RespondentDraw;

