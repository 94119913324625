const token = 'token';

export function getToken() {
  return localStorage.getItem(token);
}

export function setToken(val) {
  return localStorage.setItem(token, val);
}

export function deleteToken() {
  return localStorage.removeItem(token);
}
